
const routes = [
  {
    path: '/',
    component: () => import('components/RouterViewEmpty.vue'),
    children: [
      {
        path: '',
        component: () => import('layouts/MainLayout.vue'),
        children: [
          { path: '', name: 'home', component: () => import('pages/Home/Home.vue') },
          { path: '/comprar', name: 'comprar', component: () => import('pages/Veiculos/Veiculos.vue') },
          { path: '/vender2', name: 'vender2', component: () => import('pages/QueroVender/QueroVender.vue') },
          { path: '/assinatura', name: 'assinatura', component: () => import('pages/Assinatura/Assinatura.vue') },
          { path: '/garantia', name: 'garantia', component: () => import('pages/Gestauto/Gestauto.vue') },
          { path: '/unidades', name: 'unidades', component: () => import('pages/Unidades/Unidades.vue') },
          { path: '/vender', name: 'vender', component: () => import('pages/QueroVender/QueroVender2.vue') },
          { path: '/como-funciona', name: 'como-funciona', component: () => import('pages/QuemSomos/QuemSomos.vue') },
          { path: '/klubi', name: 'klubi', component: () => import('pages/Klubi/Klubi.vue') },
          { path: '/cadastro', name: 'cadastro', component: () => import('pages/Cadastro/Cadastro.vue') },
          { path: '/:marca/:modelo/:versao/:ano/:id/:publico?', name: 'veiculo', component: () => import('pages/Detalhes/Detalhes.vue') },
          { path: '/formulario-troca/:id', name: 'formulario-troca', component: () => import('pages/FormularioTroca/FormularioTroca.vue') },
          { path: '/avalie', name: 'avalie', component: () => import('pages/Aguarde/Aguarde.vue') },
          { path: '/modelo-de-venda', name: 'modelo-de-venda', component: () => import('pages/Aguarde/Aguarde.vue') },
          { path: '/laudo-veicular', name: 'laudo-veicular', component: () => import('pages/Aguarde/Aguarde.vue') },
          { path: '/financiamento', name: 'financiamento', component: () => import('pages/Financiamento/Financiamento.vue') },
          { path: '/regulamento/:url', name: 'termos-de-uso', component: () => import('pages/Regulamento/Regulamento.vue') },
          { path: '/quem-somos-e-como-funciona', name: 'quem-somos', component: () => import('pages/QuemSomos/QuemSomos.vue') },
          { path: '/vantagens', name: 'vantagens', component: () => import('pages/Vantagens/Vantagens.vue') },
          // { path: '/garantia', name: 'garantia', component: () => import('pages/Garantias/Garantias.vue') },
          { path: '/logistica', name: 'logistica', component: () => import('pages/Aguarde/Aguarde.vue') },
          { path: '/faq', name: 'faq', component: () => import('pages/FAQ/FAQ.vue') },
          { path: '/faq/:group/:item', name: 'faq.specific', component: () => import('pages/FAQ/FAQ.vue') },
          { path: '/login', name: 'login', component: () => import('pages/Login/Login.vue') },
          { path: '/login2', name: 'login2', component: () => import('pages/Login/Login.vue') },
          { path: '/login-admin', name: 'login-admin', component: () => import('pages/LoginAdmin/Login.vue') },
          { path: '/login-empresa', name: 'login-empresa', component: () => import('pages/LoginEmpresa/Login.vue') },
          { path: '/sair', name: 'sair', component: () => import('pages/Logout/Logout.vue') },
          { path: '/perfil', name: 'perfil', component: () => import('pages/Perfil/Perfil.vue') },
          { path: '/aguarde', name: 'aguarde', component: () => import('pages/Aguarde/Aguarde.vue') }
        ]
      },
      {
        path: '/empresa',
        component: () => import('layouts/EmpresaLayout.vue'),
        children: [
          { path: '', name: 'inicio-empresa', permissoes: ['Diretor Loja'], component: () => import('pages/Empresa/InicioEmpresa.vue') },
          // VEICULOS
          { path: 'estoques', name: 'estoque.grid', component: () => import('pages/Empresa/Anuncio/AnuncioGrid.vue') },
          { path: 'estoque/:id?', name: 'estoque.form', component: () => import('pages/Empresa/Anuncio/AnuncioForm.vue') }
        ]
      },
      {
        path: '/admin',
        component: () => import('layouts/AdminLayout.vue'),
        children: [
          { path: '', name: 'inicio-admin', permissoes: ['Administrador'], component: () => import('pages/Admin/InicioAdmin.vue') },
          { path: 'clientes', name: 'clientes.grid', component: () => import('pages/Admin/Cliente/ClienteGrid.vue') },
          { path: 'cliente/:id?', name: 'clientes.form', component: () => import('pages/Admin/Cliente/ClienteForm.vue') },
          // CONTEUDO
          { path: 'conteudos', name: 'conteudos.grid', component: () => import('pages/Admin/Conteudo/ConteudoGrid.vue') },
          { path: 'conteudo/:id?', name: 'conteudos.form', component: () => import('pages/Admin/Conteudo/ConteudoForm.vue') },
          // LOCAL
          { path: 'locais', name: 'locais.grid', component: () => import('pages/Admin/Local/LocalGrid.vue') },
          { path: 'local/:id?', name: 'locais.form', component: () => import('pages/Admin/Local/LocalForm.vue') },
          // GRUPO CATEGORIA
          { path: 'grupos-categorias', name: 'grupos-categorias.grid', component: () => import('pages/Admin/GrupoCategoria/GrupoCategoriaGrid.vue') },
          { path: 'grupos-categoria/:id?', name: 'grupos-categorias.form', component: () => import('pages/Admin/GrupoCategoria/GrupoCategoriaForm.vue') },
          // CATEGORIAS
          { path: 'categorias', name: 'categorias.grid', component: () => import('pages/Admin/Categorias/CategoriaGrid.vue') },
          { path: 'categoria/:id?', name: 'categorias.form', component: () => import('pages/Admin/Categorias/CategoriaForm.vue') },
          // CARROCERIAS
          { path: 'carrocerias', name: 'carrocerias.grid', component: () => import('pages/Admin/Carrocerias/CarroceriaGrid.vue') },
          { path: 'carroceria/:id?', name: 'carrocerias.form', component: () => import('pages/Admin/Carrocerias/CarroceriaForm.vue') },
          // CORES
          { path: 'cores', name: 'cores.grid', component: () => import('pages/Admin/Cores/CorGrid.vue') },
          { path: 'cor/:id?', name: 'cores.form', component: () => import('pages/Admin/Cores/CorForm.vue') },
          // COMBUSTÍVEIS
          { path: 'combustiveis', name: 'combustiveis.grid', component: () => import('pages/Admin/Combustiveis/CombustivelGrid.vue') },
          { path: 'combustivel/:id?', name: 'combustiveis.form', component: () => import('pages/Admin/Combustiveis/CombustivelForm.vue') },
          // CAMBIOS
          { path: 'cambios', name: 'cambios.grid', component: () => import('pages/Admin/Cambios/CambioGrid.vue') },
          { path: 'cambio/:id?', name: 'cambios.form', component: () => import('pages/Admin/Cambios/CambioForm.vue') },
          // GRUPO DE ACESSORIOS
          { path: 'grupos-acessorios', name: 'grupos-acessorios.grid', component: () => import('pages/Admin/GrupoAcessorio/GrupoAcessorioGrid.vue') },
          { path: 'grupos-acessorio/:id?', name: 'grupos-acessorios.form', component: () => import('pages/Admin/GrupoAcessorio/GrupoAcessorioForm.vue') },
          // PROPOSTAS
          { path: 'propostas', name: 'credere/propostas.grid', component: () => import('pages/Admin/Proposta/PropostaGrid.vue') },
          { path: 'proposta/:id?', name: 'propostas.form', component: () => import('pages/Admin/Proposta/PropostaForm.vue') },
          // ACESSORIOS
          { path: 'acessorios', name: 'acessorios.grid', component: () => import('pages/Admin/Acessorios/AcessorioGrid.vue') },
          { path: 'acessorio/:id?', name: 'acessorios.form', component: () => import('pages/Admin/Acessorios/AcessorioForm.vue') },
          // TAGS
          { path: 'tags', name: 'tags.grid', component: () => import('pages/Admin/Tags/TagGrid.vue') },
          { path: 'tag/:id?', name: 'tags.form', component: () => import('pages/Admin/Tags/TagForm.vue') },
          // VEICULOS
          { path: 'veiculos', name: 'bens.grid', component: () => import('pages/Admin/Veiculo/VeiculoGrid.vue') },
          { path: 'veiculo/:id?', name: 'bens.form', component: () => import('pages/Admin/Veiculo/VeiculoForm.vue') },
          // VEICULOS
          { path: 'veiculacoes', name: 'anuncios.grid', component: () => import('pages/Admin/Anuncio/AnuncioGrid.vue') },
          { path: 'veiculacao/:id?', name: 'anuncios.form', component: () => import('pages/Admin/Anuncio/AnuncioForm.vue') },
          // Versões
          { path: 'versoes', name: 'versoes.grid', component: () => import('pages/Admin/Versao/VersaoGrid.vue') },
          { path: 'versao/:id?', name: 'versoes.form', component: () => import('pages/Admin/Versao/VersaoForm.vue') },
          // Modelos
          { path: 'modelos', name: 'modelos.grid', component: () => import('pages/Admin/Modelo/ModeloGrid.vue') },
          { path: 'modelo/:id?', name: 'modelos.form', component: () => import('pages/Admin/Modelo/ModeloForm.vue') },
          // Marcas
          { path: 'marcas', name: 'marcas.grid', component: () => import('pages/Admin/Marca/MarcaGrid.vue') },
          { path: 'marca/:id?', name: 'marcas.form', component: () => import('pages/Admin/Marca/MarcaForm.vue') },
          // MÍDIAS
          { path: 'midias', name: 'midias.grid', component: () => import('pages/Admin/Midia/MidiaGrid.vue') },
          { path: 'midia/:id?', name: 'midias.form', component: () => import('pages/Admin/Midia/MidiaForm.vue') },
          // GRUPO DE VERIFICACOES
          { path: 'grupos-verificacoes', name: 'grupos-verificacoes.grid', component: () => import('pages/Admin/GrupoVerificacao/GrupoVerificacaoGrid.vue') },
          { path: 'grupos-verificacao/:id?', name: 'grupos-verificacoes.form', component: () => import('pages/Admin/GrupoVerificacao/GrupoVerificacaoForm.vue') },
          // VERIFICACOES
          { path: 'verificacoes', name: 'verificacoes.grid', component: () => import('pages/Admin/Verificacao/VerificacaoGrid.vue') },
          { path: 'verificacao/:id?', name: 'verificacoes.form', component: () => import('pages/Admin/Verificacao/VerificacaoForm.vue') },
          // VERIFICACOES
          { path: 'situacoes-laudos', name: 'situacoes-laudos.grid', component: () => import('pages/Admin/SituacaoLaudo/SituacaoLaudoGrid.vue') },
          { path: 'situacao-laudo/:id?', name: 'situacoes-laudos.form', component: () => import('pages/Admin/SituacaoLaudo/SituacaoLaudoForm.vue') },
          // LAUDOS
          { path: 'laudos', name: 'laudos.grid', component: () => import('pages/Admin/Laudo/LaudoGrid.vue') },
          { path: 'laudo/:id?', name: 'laudos.form', component: () => import('pages/Admin/Laudo/LaudoForm.vue') }
        ]
      },
      {
        path: '/painel',
        component: () => import('layouts/PainelLogadoLayout.vue'),
        children: [
          { path: '', name: 'painel-inicio', component: () => import('pages/Painel/Inicio/Inicio.vue') },
          { path: 'proposta-enviada', name: 'painel-proposta-enviada', component: () => import('pages/Painel/PropostaEnviada/PropostaEnviada.vue') },
          { path: 'veiculo/:id/agendamento-inicio', name: 'painel-agendamento-inicio', component: () => import('pages/Painel/PropostaEnviada/PropostaEnviada.vue') },
          { path: 'veiculo/:id/delivery', name: 'painel-delivery', component: () => import('pages/Painel/Delivery/Delivery.vue') },
          { path: 'veiculo/:id/testdrive', name: 'painel-test-drive', component: () => import('pages/Painel/TestDrive/TestDrive.vue') },
          { path: 'veiculo/:id/videochamada', name: 'painel-videochamada', component: () => import('pages/Painel/Videochamada/Videochamada.vue') },
          { path: 'proposta/editar/:id/anuncio/ref/:propostaid?', name: 'painel-proposta-editar', component: () => import('pages/Painel/Proposta/Proposta.vue') },
          { path: 'veiculo/:id/proposta/:publico?', name: 'painel-proposta', component: () => import('pages/Painel/Proposta/Proposta.vue') }
        ]
      },
      // Always leave this as last one,
      // but you can also remove it
      {
        path: '*',
        component: () => import('pages/Error404.vue')
      }
    ]
  }
]

export default routes
