import { uid } from 'quasar'

export default {
  logEventGA (eventCategory, eventAction, eventLabel, eventValue = 1) {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('config', 'UA-6294819-1')
      window.gtag('event', 'click', {
        event_category: eventCategory,
        event_action: eventAction,
        event_label: eventLabel,
        event_value: eventValue
      })
    }
  },
  logPage: function (dados, sessionId = null) {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('config', 'UA-179555496-1', dados)
      window.gtag('send', 'pageview')
    }

    // window.gtag('send', 'pageview', dados)
    // window.dataLayer.push({
    //   'screenPath': path,
    //   'screenName': name,
    //   'sessionId': sessionId
    // })
    //
    // window.dataLayer.push({ 'event': 'appScreenView' })

    // window.gtag('config', 'UA-63672676-1', {
    //   // 'page_title': 'Your custom title',
    //   'page_path': path
    // })

    // ga('set', 'page', path)
    // ga('send', 'pageview')
  },
  getCid () {
    // We need an unique identifier for this session
    // We store it in a localStorage, but you may use cookies, too
    if (!localStorage.cid) {
      localStorage.cid = uid()
    }
    return localStorage.cid
  }
}
