const appIcones = {
  // Novos ícones
  'app:icon-color-delivery': 'img:/icones/layout/color/delivery.svg',
  'app:icon-color-drive': 'img:/icones/layout/color/drive.svg',
  'app:icon-color-financing': 'img:/icones/layout/color/financing.svg',
  'app:icon-color-guarantee': 'img:/icones/layout/color/guarantee.svg',

  'app:icon-arrow-left': 'img:/icones/layout/arrow-left.svg',
  'app:icon-arrow-right': 'img:/icones/layout/arrow-right.svg',
  'app:icon-arrow-down': 'img:/icones/layout/arrow-down.svg',
  'app:icon-arrow-clockwise': 'img:/icones/layout/arrow-clockwise.svg',
  'app:icon-award': 'img:/icones/layout/award.svg',
  'app:icon-broom': 'img:/icones/layout/broom.svg',
  'app:icon-calendar-2': 'img:/icones/layout/calendar-2.svg',
  'app:icon-calendar': 'img:/icones/layout/calendar.svg',
  'app:icon-car-front': 'img:/icones/layout/car-front.svg',
  'app:icon-car-ok': 'img:/icones/layout/car-ok.svg',
  'app:icon-car-top': 'img:/icones/layout/car-top.svg',
  'app:icon-car': 'img:/icones/layout/car.svg',
  'app:icon-caret-up': 'img:/icones/layout/caret-up.svg',
  'app:icon-check-circle': 'img:/icones/layout/check-circle.svg',
  'app:icon-check': 'img:/icones/layout/check.svg',
  'app:icon-chevron-left': 'img:/icones/layout/png/chevron-left.png',
  'app:icon-chevron-right': 'img:/icones/layout/png/chevron-right.png',
  'app:icon-circle-notch': 'img:/icones/layout/circle-notch.svg',
  'app:icon-close': 'img:/icones/layout/close.svg',
  'app:icon-delivery': 'img:/icones/layout/delivery.svg',
  'app:icon-device-mobile': 'img:/icones/layout/device-mobile.svg',
  'app:icon-eye': 'img:/icones/layout/eye.svg',
  'app:icon-faders-horizontal': 'img:/icones/layout/faders-horizontal.svg',
  'app:icon-files': 'img:/icones/layout/files.svg',
  'app:icon-fone': 'img:/icones/layout/fone.svg',
  'app:icon-hamburger': 'img:/icones/layout/hamburger.svg',
  'app:icon-home-ok': 'img:/icones/layout/home-ok.svg',
  'app:icon-home': 'img:/icones/layout/home.svg',
  'app:icon-key': 'img:/icones/layout/key.svg',
  'app:icon-km': 'img:/icones/layout/km.svg',
  'app:icon-magnifying-glass': 'img:/icones/layout/magnifying-glass.svg',
  'app:icon-map-pin': 'img:/icones/layout/map-pin.svg',
  'app:icon-minus': 'img:/icones/layout/minus.svg',
  'app:icon-money': 'img:/icones/layout/money.svg',
  'app:icon-outlet': 'img:/icones/layout/outlet.svg',
  'app:icon-play-circle': 'img:/icones/layout/play-circle.svg',
  'app:icon-plus-circle': 'img:/icones/layout/plus-circle.svg',
  'app:icon-plus': 'img:/icones/layout/plus.svg',
  'app:icon-tag': 'img:/icones/layout/tag.svg',
  'app:icon-timer': 'img:/icones/layout/timer.svg',
  'app:icon-tooltip': 'img:/icones/layout/tooltip.svg',
  'app:icon-warning': 'img:/icones/layout/warning.svg',

  // painel
  'app:painel_agendar_delivery': 'img:/painel/icones/agendar-delivery.svg',
  'app:painel_agendar_delivery_ativo': 'img:/painel/icones/agendar-delivery-ativo.svg',
  'app:painel_agendar_test_drive': 'img:/painel/icones/agendar-test-drive.svg',
  'app:painel_agendar_test_drive_ativo': 'img:/painel/icones/agendar-test-drive-ativo.svg',
  'app:painel_agendar_videochamada': 'img:/painel/icones/agendar-videochamada.svg',
  'app:painel_agendar_videochamada_ativo': 'img:/painel/icones/agendar-videochamada-ativo.svg',
  'app:painel_completar_proposta': 'img:/painel/icones/completar-proposta.svg',
  'app:painel_completar_proposta_ativo': 'img:/painel/icones/completar-proposta-ativo.svg',
  // modal
  'app:modal_desmarcar': 'img:/painel/modal/desmarcar.svg',
  'app:modal_agendado': 'img:/painel/modal/agendado.svg',
  'app:modal_reagendar': 'img:/painel/modal/reagendar.svg',
  'app:modal_sucesso': 'img:/painel/modal/sucesso.svg',
  'app:modal_financiamento': 'img:/painel/modal/financiamento.svg',
  // icones
  'app:calendario': 'img:/painel/icones/calendario.svg',
  'app:relogio': 'img:/painel/icones/relogio.svg',

  'app:proposta_analise': 'img:/painel/icones/proposta-analise.svg',
  'app:continue-proposta': 'img:/painel/icones/continue-proposta.svg',
  'app:proposta-aprovado': 'img:/painel/icones/proposta-aprovado.svg',
  'app:proposta-aprovado-alteracao': 'img:/painel/icones/proposta-aprovado-alteracao.svg',

  // vantagens
  'app:vantagem_1': 'img:/icones/vantagens/1/1.svg',
  'app:vantagem_1_ativo': 'img:/icones/vantagens/1/1_ativo.svg',
  'app:vantagem_1_2': 'img:/icones/vantagens/2/1.svg',
  'app:vantagem_1_2_ativo': 'img:/icones/vantagens/2/1_ativo.svg',
  'app:vantagem_2': 'img:/icones/vantagens/1/2.svg',
  'app:vantagem_2_ativo': 'img:/icones/vantagens/1/2_ativo.svg',
  'app:vantagem_2_2': 'img:/icones/vantagens/2/2.svg',
  'app:vantagem_2_2_ativo': 'img:/icones/vantagens/2/2_ativo.svg',
  'app:vantagem_3': 'img:/icones/vantagens/1/3.svg',
  'app:vantagem_3_ativo': 'img:/icones/vantagens/1/3_ativo.svg',
  'app:vantagem_3_2': 'img:/icones/vantagens/2/3.svg',
  'app:vantagem_3_2_ativo': 'img:/icones/vantagens/2/3_ativo.svg',
  'app:vantagem_4': 'img:/icones/vantagens/1/4.svg',
  'app:vantagem_4_ativo': 'img:/icones/vantagens/1/4_ativo.svg',
  'app:vantagem_4_2': 'img:/icones/vantagens/2/4.svg',
  'app:vantagem_4_2_ativo': 'img:/icones/vantagens/2/4_ativo.svg',
  // vender
  'app:vender_1': 'img:/icones/vender/1.svg',
  'app:vender_2': 'img:/icones/vender/2.svg',
  'app:vender_3': 'img:/icones/vender/3.svg',
  'app:vender_4': 'img:/icones/vender/4.svg',
  'app:vender_5': 'img:/icones/vender/5.svg',
  'app:vender_6': 'img:/icones/vender/6.svg',
  // garantias
  'app:garantias_1': 'img:/icones/garantias/1.svg',
  'app:garantias_2': 'img:/icones/garantias/2.svg',
  'app:garantias_3': 'img:/icones/garantias/3.svg',
  'app:garantias_4': 'img:/icones/garantias/4.svg',
  'app:garantias_5': 'img:/icones/garantias/5.svg',
  'app:garantias_6': 'img:/icones/garantias/6.svg',
  // home
  'app:home_1': 'img:/icones/home/1.svg',
  'app:home_2': 'img:/icones/home/2.svg',
  'app:home_3': 'img:/icones/home/3.svg',
  'app:home_4': 'img:/icones/home/4.svg',
  // menu usuario logado navbar
  'app:painel': 'img:/icones/menu-navbar/painel.svg',
  'app:perfil': 'img:/icones/menu-navbar/perfil.svg',
  'app:avaliacao': 'img:/icones/menu-navbar/avaliacao.svg',
  'app:politica': 'img:/icones/menu-navbar/politica.svg',
  'app:sair': 'img:/icones/menu-navbar/sair.svg',
  'app:sair_2': 'img:/icones/menu-navbar/sair_2.svg',
  // propostas
  'app:proposta_1': 'img:/icones/propostas/1.svg',
  'app:proposta_2': 'img:/icones/propostas/2.svg',
  'app:whatsapp': 'img:/icones/propostas/whatsapp.svg',
  'app:tela': 'img:/icones/propostas/tela.svg',
  'app:facebook': 'img:/icones/propostas/facebook.svg',
  'app:google': 'img:/icones/propostas/google.svg',
  'app:raio': 'img:/zap.svg',
  'app:delivery': 'img:/delivery.svg',
  'app:outlet': 'img:/outlet.svg',
  'app:dollar': 'img:/dollar.svg',
  'app:blackfriday': 'img:/blackfriday.svg',
  'app:cyberweek': 'img:/cyberweek.svg',
  'app:aplicativo': 'img:/Nexus5.svg',
  // detalhes
  'app:facil_1': 'img:/detalhes/1.svg',
  'app:facil_2': 'img:/detalhes/2.svg',
  'app:facil_3': 'img:/detalhes/3.svg',
  'app:facil_4': 'img:/detalhes/4.svg',
  'app:facil_1_alt': 'img:/detalhes/1-alt.svg',
  'app:facil_2_alt': 'img:/detalhes/2-alt.svg',
  'app:facil_3_alt': 'img:/detalhes/3-alt.svg',
  'app:facil_4_alt': 'img:/detalhes/4-alt.svg',
  'app:carroceria': 'img:/icones/detalhes/carroceria.svg',
  'app:km': 'img:/icones/detalhes/km.svg',
  'app:km2': 'img:/icones/detalhes/km2.svg',
  'app:carroceria2': 'img:/icones/detalhes/carroceria2.svg',
  'app:cambio': 'img:/icones/detalhes/cambio.svg',
  'app:cambio2': 'img:/icones/detalhes/cambio2.svg',
  'app:calendar': 'img:/icones/detalhes/calendar.svg',
  'app:gasolina': 'img:/icones/detalhes/gasolina.svg',
  'app:gasolina2': 'img:/icones/detalhes/gasolina2.svg',
  'app:motor': 'img:/icones/detalhes/motor.svg',
  'app:motor2': 'img:/icones/detalhes/motor2.svg',
  'app:passageiro': 'img:/icones/detalhes/passageiro.svg',
  'app:passageiro2': 'img:/icones/detalhes/passageiro2.svg',
  // financiamento
  'app:financiamento_1': 'img:/icones/financiamento/1.svg',
  'app:financiamento_2': 'img:/icones/financiamento/2.svg',
  'app:financiamento_3': 'img:/icones/financiamento/3.svg',
  // Botoes
  'app:proximo': 'img:/icones/proximo.svg',
  'app:anterior': 'img:/icones/anterior.svg',
  'app:info': 'img:/icones/info.svg',
  // Menu
  'app:menu': 'img:/icones/hamburger.svg'
}

export default {
  name: 'App',
  created () {
    this.$q.iconMapFn = iconName => {
      const icon = appIcones[iconName]
      if (icon !== void 0) {
        return { icon: icon }
      }
    }
  }
}
