import axios from 'axios'

export default {
  namespaced: true,
  state: () => ({
    data: null
  }),
  actions: {
    postData: ({ commit, state }, payload) => {
      return axios.get().then(response => {
        commit('putData', response.data.dados)
      }).catch((error) => {
        console.log(error)
      })
    }
  },
  getters: {
    getData: (state) => {
      return state.data
    }
  },
  mutations: {
    putData: (state, payload) => {
      state.data = payload
    }
  }
}
