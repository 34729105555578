import { register } from 'register-service-worker'

// The ready(), registered(), cached(), updatefound() and updated()
// events passes a ServiceWorkerRegistration instance in their arguments.
// ServiceWorkerRegistration: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration
function urlBase64ToUint8Array (base64String) {
  var padding = '='.repeat((4 - base64String.length % 4) % 4)
  var base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/')

  var rawData = window.atob(base64)
  var outputArray = new Uint8Array(rawData.length)

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}
register(process.env.SERVICE_WORKER_FILE, {
  // The registrationOptions object will be passed as the second argument
  // to ServiceWorkerContainer.register()
  // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register#Parameter

  // registrationOptions: { scope: './' },

  ready (registration) {
    // console.log('Service worker is active.')
    console.log('Service Worker registrado com sucesso:', registration)

    // Agora você pode usar a variável `registration`
    registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array('BM4-Vxi8tJieJhikyND5vMID0mjzLff-oU8gEcVSkAZkn2HCUZP5lZIQX9U9L-jGbf1RQ8BirX_TvlLEbD-v34E')
    }).then(function (subscription) {
      console.log('Assinatura criada com sucesso:', subscription)
      const keyP256dh = subscription.getKey('p256dh')
      const keyAuth = subscription.getKey('auth')

      const base64KeyP256dh = btoa(String.fromCharCode.apply(null, new Uint8Array(keyP256dh)))
      const base64KeyAuth = btoa(String.fromCharCode.apply(null, new Uint8Array(keyAuth)))
      const data = {
        endpoint: subscription.endpoint,
        public_key: base64KeyP256dh,
        auth_token: base64KeyAuth
      }
      // Envie a assinatura para o backend
      // Por exemplo, usando fetch:
      fetch(process.env.API['usuario'] + '/push/subscriptions', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }).catch(function (error) {
      console.error('Falha ao criar a assinatura:', error)
    })
  },

  registered (/* registration */) {
    // console.log('Service worker has been registered.')
  },

  cached (/* registration */) {
    // console.log('Content has been cached for offline use.')
  },

  updatefound (/* registration */) {
    // console.log('New content is downloading.')
  },

  updated (/* registration */) {
    // console.log('New content is available; please refresh.')
  },

  offline () {
    // console.log('No internet connection found. App is running in offline mode.')
  },

  error (/* err */) {
    // console.error('Error during service worker registration:', err)
  }
})
