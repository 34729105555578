// import something here

// "async" is optional
// export default async ({ /* app, router, Vue, ... */ }) => {
//   // something to do
// }
import ga from 'src/components/analytics'
export default ({ router }) => {
  router.afterEach((to, from) => {
    let dados = { 'page_path': to.path.split('?')[0] }
    if (typeof to.params.title !== 'undefined') {
      dados = { 'page_path': to.path.split('?')[0], 'page_title': to.params.title }
    }
    if (dados.page_path.indexOf('admin') === -1 || dados.page_path.indexOf('admin') >= 5) {
      ga.logPage(dados)
    }
  })
}
